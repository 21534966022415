<template>
  <div class="common-dialog">
    <el-dialog
      top="90px"
      class="common-dia-modal"
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      @close="beforeClose"
      @closed="closed"
      :modal="showMask"
      :width="dialogStatus.dialogWidth"
    >
      <div
        :class="showHeaderBorder ? 'dialog-header' : 'dislog-header-noborder'"
      >
        <span style="color: #fff">{{ dialogStatus.dialogTitle }}</span>
      </div>
      <div class="modal_slot_cont" :style="contentStyle">
        <slot name="modalCont"></slot>
      </div>
      <slot name="submit"></slot>
      <div
        slot="footer"
        v-if="dialogStatus.showFoot"
        class="dialog-footer common-foot-dia"
      >
        <div
          v-if="showCheck"
          class="botton-body line-15 m-l-18 m-t-10 now-all-type"
          style="border:none;margin-left:10px!important;margin-top:2px!important;float: left;"
        >
          <el-checkbox v-model="checked" @change="handleCheckAllChange"
            >下次不再显示</el-checkbox
          >
        </div>
        <slot name="footerRight"></slot>

        <el-button
          size="small"
          @click="submitContBtn"
          v-if="dialogStatus.delModel"
          v-loading="btnLoading"
        >
          {{ dialogStatus.dialogSurBtn }}
        </el-button>
        <el-button
          size="small"
          v-if="!dialogStatus.singleBtn"
          @click="subimtCancle"
        >
          取消
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="submitContBtn"
          v-if="!dialogStatus.delModel"
          v-loading="btnLoading"
        >
          {{ dialogStatus.dialogSurBtn }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  props: {
    contentStyle: {
      type: String,
      default: ''
    },
    showHeaderBorder: {
      type: Boolean,
      default: true
    },
    dialogStatus: {
      type: Object,
      default: {
        showFoot: true,
        noAllow: false,
        delModel: false
      }
    },
    btnLoading: {
      type: Boolean,
      default: false
    },
    showCheck: {
      type: Boolean,
      default: false
    },
    showMask: {
      type: Boolean,
      default: true
    },
    // 关闭回调 // 这个组件设计的真xx
    onClose: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      dialogVisible: false,
      checked: false
    }
  },
  methods: {
    init() {
      this.dialogVisible = true
      this.checked = false
    },
    handleCheckAllChange() {
      this.$emit('handleCheckAllChange', this.checked)
    },
    beforeClose() {
      this.$store.dispatch('setGoBuyMeal', false)
    },
    closed() {
      this.$emit('closed')
      this.onClose()
    },

    submitContBtn: debounce(
      function() {
        if (this.dialogStatus.noAllow) {
          return
        }
        this.$emit('submitContBtn', true)
      },
      1000,
      { leading: true, trailing: false }
    ),

    subimtCancle() {
      this.dialogVisible = false
      this.$emit('subimtCancle', true)
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
.common-dialog {
  font-size: 12px;
}
.common-dia-modal ::v-deep .el-dialog__close {
  color: #fff;
}
.common-dia-modal ::v-deep .el-dialog__header {
  color: #fff !important;
}

.sure-btn-allow {
  background: #dcdfe6 !important;
}
.have_cacle_btn {
  display: inline-block;
  width: 80px;
  text-align: center;
  height: 34px;
  border-radius: 4px;
  vertical-align: top;
  line-height: 34px;
  cursor: pointer;
  border: 1px solid #dcdfe6;
}
.dialog_modal_btn {
  display: inline-block;
  width: 100px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  background: $main-color;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}
.modal_slot_cont {
  padding: 0 10px;
  margin: 10px 0;
  box-sizing: border-box;
  max-height: 400px;
  overflow-y: auto;
}
.dialog-header {
  height: 50px;
  background: $main-color;
  padding-left: 20px;
  display: flex;
  align-items: center;
}
.dislog-header-noborder {
  height: 50px;
  background: $main-color;
  padding-left: 20px;
  display: flex;
  align-items: center;
}
.dialog-header span {
  position: relative;
  top: 2px;
}
.common-dia-modal ::v-deep .el-dialog__header {
  padding: 0px !important;
}
.common-dia-modal ::v-deep .el-dialog__body {
  padding: 0px !important;
}
.common-foot-dia {
  position: relative;
}
.common-foot-dia::before {
  position: absolute;
  display: inline-block;
  content: '';
  height: 1px;
  width: calc(100% + 40px);
  background-color: rgba(0, 0, 0, 0.1);
  top: -10px;
  left: -20px;
  transform: scaleY(0.5);
}
</style>
