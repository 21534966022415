<template>
  <div class="successXiaoMi-main">
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="h">AI销售专用工作手机</div>
        <div class="img">
          <el-image
            style="height: 270px; width: 100%"
            :src="require('../../../assets/mi.jpg')"
            fit="contain "
          ></el-image>
        </div>
        <div class="logo">
          <el-image
            style="width: 200px"
            :src="require('../../../assets/hx/ln-logo.png')"
            fit="contain "
          ></el-image>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="s-h">集成：销售线索+电销工具+私域营销工具+CRM</div>
        <div class="l">
          <div class="er-code"></div>
          <div class="help-you">帮你解决以下难题：</div>
          <div class="li">客户资源流失风险</div>
          <div class="li">员工接私活飞单</div>
          <div class="li">离职带走客户</div>
          <div class="li bottom">
            无法监管销售沟通内容，主管无法分析丢单原因
          </div>

          <div class="li">外出拜访无法监管</div>
          <div class="li">绩效考核不科学</div>
          <div class="li">销售过程无法量化</div>
          <div class="li">任务追踪繁琐困难</div>
        </div>
      </el-col>
      <el-col :span="24">
        <div class="btn">
          <el-button
            size="small"
            type="primary"
            @click="getSim"
            v-loading="loading"
          >
            立即申请试用
          </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { applySim } from '@/api/thread'

export default {
  name: 'successXiaoMi',
  data() {
    return {
      loading: false
    }
  },
  mounted() {},
  computed: {},
  methods: {
    getSim() {
      if (this.loading) return
      this.loading = true
      applySim({ type: 9 })
        .then(res => {
          this.loading = false
          if (res.code === 60000) {
            this.$store.commit('update', {
              target: this.$store.state.plugin.modal.options,
              data: {
                isOk: true
              }
            })
            this.$store.dispatch('hideModal')
          }
        })
        .catch(() => {
          this.loading = false
        })
    }
  },
  beforeDestroy() {}
}
</script>

<style scoped>
.successXiaoMi-main {
}
.h {
  color: #0967dc;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  height: 80px;
  line-height: 80px;
}
.s-h {
  color: #0967dc;
  font-size: 16px;
  font-weight: bold;
  height: 100px;
  line-height: 90px;
}
.help-you {
  font-weight: bold;
  color: #333333;
  margin-bottom: 30px;
}
.bottom {
  margin-bottom: 20px;
}
.li {
  padding: 5px 20px;
  box-sizing: border-box;
  position: relative;
  color: #333333;
}
.li:before {
  content: '';
  display: inline-block;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #333333;
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
}
.l {
  position: relative;
}
.er-code {
  position: absolute;
  right: 45px;
  bottom: -10px;
  background: url('https://dtbird-platform.oss-cn-shanghai.aliyuncs.com/images/lner.png')
    no-repeat center center;
  background-size: 100px 100px;
  height: 120px;
  width: 120px;
}
.er-code:before {
  content: '联系客服';
  display: inline-block;
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 12px;
}
.logo {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
}
.btn {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
</style>
