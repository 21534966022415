import Vue from 'vue'
import 'normalize.css/normalize.css'
import ElementUI from 'element-ui'
import { Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './styles/style/theme/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN'
import 'driver.js/dist/driver.min.css'
import '@/styles/index.scss'
import App from './App'
import store from './store'
import router from './router'
import vcolorpicker from 'vcolorpicker'
import { AppWebSockt } from '@/utils/websocket'
import bus from './utils/bus'
import '@/icons'
import './cores/Directive' //自定义指令
import './utils/errorHandel' //上报错误信息
import './cores/feishu'
import './cores/dingtalk'
import cookie from 'js-cookie'
import vueAudioNative from 'vue-audio-native'
import animated from 'animate.css'
import elCascaderMulti from 'el-cascader-multi'
import { timeout } from '@/utils/helper'
import { config } from '@/config'
//自定义全局组件
import Star from '@/components/Peculiar/stars'
import Tags from '@/components/Peculiar/tags'
import Icon from '@/components/Peculiar/icon'
import NoData from '@/components/Peculiar/no-data'
Vue.component('Star', Star)
Vue.component('Tags', Tags)
Vue.component('Icon', Icon)
Vue.component('NoData', NoData)

Vue.prototype.$message = Message
Vue.prototype.$bus = bus
Vue.prototype.$timeout = timeout
Vue.prototype.$updateText = config.text

Vue.use(vcolorpicker)
Vue.use(vueAudioNative)
Vue.use(ElementUI, { locale })
Vue.use(elCascaderMulti)
Vue.use(animated)

//刷新保存登录状态
store.commit('setToken', cookie.get('token'))
store.dispatch('changeStep', localStorage.getItem('dtBird_user_step'))
if (localStorage.getItem('env') === 'tt') {
  store.commit('update', {
    target: store.state.plugin,
    data: { env: 'tt' }
  })
}

//主题色配置
document
  .getElementsByTagName('body')[0]
  .style.setProperty('--main-color', process.env.VUE_APP_BASE_Theme)

AppWebSockt()

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
