<template>
  <div class="create-div" v-loading="loading">
    <permission
      :key="key"
      :permission-list="permissionList"
      @save="save"
      type="新建角色"
    />
  </div>
</template>

<script>
import { createRole, saveDataPermission } from '@/api/permission'
import permission from './component/permission'
export default {
  components: {
    // View,
    permission
  },
  data() {
    return {
      loading: false,
      key: 1
    }
  },
  computed: {
    permissionList() {
      // this.key = this.key + 1 //刷新组件
      return this.$store.state.permission.panelList
    }
  },
  methods: {
    tip(msg, type = 'success', timer = 800) {
      this.$message({
        duration: timer,
        message: msg,
        type: type
      })
    },

    // 处理数据权限
    handelDataPermission(list) {
      const result = []
      list &&
        list.forEach(menu => {
          const pages = menu.pages
          pages &&
            pages.forEach(page => {
              if (page.dataPermissionModel) {
                result.push({
                  page_id: page.id,
                  scope: page.dataPermissionModel['scope'],
                  user_ids: page.dataPermissionModel['user_ids']
                })
              }
            })
        })
      return JSON.stringify(result)
    },

    async save(data) {
      this.loading = true
      let params = {
        rolePermission: JSON.stringify(data.data),
        roleName: data.name
      }
      try {
        const res = await createRole(params)
        if (res && res.code === 60000 && res.data && res.data.code === 60000) {
          const dataRes = await this.createDataPermission({
            roleId: res.data.data,
            dataPermission: this.handelDataPermission(data.data)
          })
          if (dataRes) {
            this.tip('新建角色成功')
            this.$router.replace('/permission/permission')
          } else {
            this.tip('新建角色失败')
          }
        } else {
          this.tip(
            res.data.code !== 60000 ? res.data.msg : '新建角色失败',
            'error'
          )
        }
      } catch (e) {
        this.tip('出错了', 'error')
      }
      this.loading = false
    },

    createDataPermission(params) {
      return new Promise(resolve => {
        saveDataPermission(params)
          .then(res => {
            if (res && res.code === 60000) {
              resolve(true)
              return
            }
            resolve(false)
          })
          .catch(() => {
            resolve(false)
          })
      })
    }
  }
}
</script>

<style scoped>
.create-div {
  /*height: 100%;*/
  width: 100%;
}

/* 隐藏横向滚动条 */
.create-div ::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
