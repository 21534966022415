var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "successXiaoMi-main" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "h" }, [_vm._v("AI销售专用工作手机")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "img" },
              [
                _c("el-image", {
                  staticStyle: { height: "270px", width: "100%" },
                  attrs: {
                    src: require("../../../assets/mi.jpg"),
                    fit: "contain ",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "logo" },
              [
                _c("el-image", {
                  staticStyle: { width: "200px" },
                  attrs: {
                    src: require("../../../assets/hx/ln-logo.png"),
                    fit: "contain ",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "s-h" }, [
              _vm._v("集成：销售线索+电销工具+私域营销工具+CRM"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "l" }, [
              _c("div", { staticClass: "er-code" }),
              _vm._v(" "),
              _c("div", { staticClass: "help-you" }, [
                _vm._v("帮你解决以下难题："),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "li" }, [_vm._v("客户资源流失风险")]),
              _vm._v(" "),
              _c("div", { staticClass: "li" }, [_vm._v("员工接私活飞单")]),
              _vm._v(" "),
              _c("div", { staticClass: "li" }, [_vm._v("离职带走客户")]),
              _vm._v(" "),
              _c("div", { staticClass: "li bottom" }, [
                _vm._v(
                  "\n          无法监管销售沟通内容，主管无法分析丢单原因\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "li" }, [_vm._v("外出拜访无法监管")]),
              _vm._v(" "),
              _c("div", { staticClass: "li" }, [_vm._v("绩效考核不科学")]),
              _vm._v(" "),
              _c("div", { staticClass: "li" }, [_vm._v("销售过程无法量化")]),
              _vm._v(" "),
              _c("div", { staticClass: "li" }, [_vm._v("任务追踪繁琐困难")]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "btn" },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.getSim },
                  },
                  [_vm._v("\n          立即申请试用\n        ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }